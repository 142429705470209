// import { useRouter } from 'next/router'
import Head from 'next/head'

const TagAlternate = ({ hrefDefault = '', hrefIDID = '', hrefENID = '' }) => {
   // const router = useRouter()
   // const locale = router.locale !== 'en' ? '/' + router.locale : ''
   // const currentURL = 'https://globalxtreme.net' + locale + router.asPath
   // const currentURLIDID = 'https://globalxtreme.net/id' + router.asPath
   // const currentURLENID = 'https://globalxtreme.net' + router.asPath

   // const hrefLang =
   //    router.asPath === '/' && router.locale === 'en'
   //       ? 'x-default'
   //       : router.locale === 'en'
   //       ? 'en-id'
   //       : 'id-id'

   return (
      <>
         <Head>
            <link
               rel="alternate"
               href={'https://globalxtreme.net' + hrefDefault}
               hrefLang="x-default"
            />
            <link
               rel="alternate"
               href={'https://globalxtreme.net' + hrefIDID}
               hrefLang="id-id"
            />
            <link
               rel="alternate"
               href={'https://globalxtreme.net' + hrefENID}
               hrefLang="en-id"
            />
         </Head>
      </>
   )
}

export default TagAlternate
